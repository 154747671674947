import {
  type DefaultError,
  type FetchQueryOptions,
  type QueryKey,
  useQueryClient,
} from '@tanstack/react-query'

export const usePrefetchQuery = <
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: FetchQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  enabled: boolean = true
) => {
  const queryClient = useQueryClient()

  if (enabled && !queryClient.getQueryState(options.queryKey)) {
    queryClient.prefetchQuery(options)
  }
}
