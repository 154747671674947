import { analyticsLog } from '@src/utils/analyticsLog'
import { DateTable } from './DateTable'
import TableTitleList from './DateTable/TableTitleList'
import { DatePickerPropsExtended } from './types'
import { COMPONENT_NAME, PAGE_NAME } from '@src/utils/analyticsLog/constants'
import { useRouter } from 'next/router'

const DatePicker = ({
  todayDate,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  monthRange = 6,
  maxSelectedDate = 14,
  isPastDateActive = false,
  isOnlySetInRange = true,
  startDateCharacter,
  endDateCharacter,
  todayCharacter,
  holidays,
  ...others
}: DatePickerPropsExtended) => {
  const router = useRouter()

  const handleDateItemClick = (date: Date) => {
    // 왕복 선택 시
    if (onEndDateChange) {
      if (startDate && endDate) {
        onStartDateChange(date)
        logSelectDate('start')
        onEndDateChange(null)
        return
      }

      if (startDate?.valueOf() === date.valueOf()) {
        onEndDateChange(date)
        logSelectDate('end')
        return
      }

      if (!startDate && !endDate) {
        onStartDateChange(date)
        logSelectDate('start')
        return
      }

      if (startDate && !endDate) {
        if (startDate.valueOf() >= date.valueOf()) {
          onStartDateChange(date)
          logSelectDate('start')
          return
        }
        onEndDateChange(date)
        logSelectDate('end')
      }
    } else {
      // 편도 선택 시
      onStartDateChange(date)
      logSelectDate('start')
    }
  }

  const logSelectDate = (type: 'start' | 'end') => {
    const isFlightsPage = router.pathname === '/flights/[type]'

    isFlightsPage &&
      analyticsLog('click', [
        { key: 'page_name', value: PAGE_NAME.flight_ticket_search },
        {
          key: 'component_name',
          value:
            type === 'start'
              ? COMPONENT_NAME.search_departure_flight_select
              : COMPONENT_NAME.search_return_flight_select,
        },
      ])
  }

  return (
    <div className="tw-w-full" {...others}>
      <DateTable
        startDateCharacter={startDateCharacter}
        endDateCharacter={endDateCharacter}
        todayCharacter={todayCharacter}
        todayDate={todayDate}
        startDate={startDate}
        endDate={endDate}
        monthRange={monthRange}
        maxSelectedDate={maxSelectedDate}
        isPastDateActive={isPastDateActive}
        isOnlySetInRange={isOnlySetInRange}
        holidays={holidays}
        handleDateItemClick={handleDateItemClick}
      />
    </div>
  )
}

export default Object.freeze(
  Object.assign(DatePicker, {
    TitleList: TableTitleList,
  })
)
