const SEARCH_TYPE = {
  DEPARTURE: 'DEPARTURE',
  ARRIVAL: 'ARRIVAL',
  ONE_WAY: 'ONE_WAY',
  ROUND_TRIP: 'ROUND_TRIP',
  PERSONNEL: 'PERSONNEL',
} as const

export type SearchType = keyof typeof SEARCH_TYPE

export const PERSONNEL_MAX_ALERT = '예약 인원은 최대 9명까지 예약 가능합니다.'
export const INFANT_CONDITION_ALERT =
  '유아 1명 당 성인 1명이 포함 되어야합니다.'
