export const PAGE_NAME = {
  flight_main: 'flight_main',
  flight_ticket_search: 'flight_ticket_search',
  flight_ticket_search_bottom: 'flight_ticket_search_bottom',
  flight_ticket_reservation: 'flight_ticket_reservation',
  flight_ticket_reservation_bottom: 'flight_ticket_reservation_bottom',
  flight_ticket_payment: 'flight_ticket_payment',
  flight_ticket_payment_complete: 'flight_ticket_payment_complete',
} as const

export const COMPONENT_NAME = {
  main_tab_roundtrip: 'main_tab_roundtrip',
  main_tab_oneway: 'main_tab_oneway',
  main_tab_departure: 'main_tab_departure',
  main_tab_destination: 'main_tab_destination',
  main_calendar_Complete_btn: 'main_calendar_Complete_btn',
  main_passenger_seats_Complete_btm: 'main_passenger_seats_Complete_btm',
  main_flight_ticket_search_btn: 'main_flight_ticket_search_btn',
  main_login: 'main_login',
  main_reservation: 'main_reservation',
  search_departure_flight_select: 'search_departure_flight_select',
  search_return_flight_select: 'search_return_flight_select',
  search_next_btn: 'search_next_btn',
  quick_login_btn: 'quick_login_btn',
  guest_reservation: 'guest_reservation',
  flight_ticket_reservation_complete_btn:
    'flight_ticket_reservation_complete_btn',
  flight_ticket_reservation_confirm_btn:
    'flight_ticket_reservation_confirm_btn',
  flight_ticket_payment_complete_btn: 'flight_ticket_payment_complete_btn',
} as const
