import { tv } from 'tailwind-variants'

export const ctaButtonVariant = tv({
  base: 'tw-flex tw-justify-center tw-items-center tw-font-bold tw-rounded-12 tw-whitespace-nowrap disabled:tw-bg-grey025 disabled:tw-text-grey040',
  variants: {
    buttonStyle: {
      primary:
        'tw-bg-grey060 tw-text-white disabled:tw-text-grey040 disabled:tw-bg-grey025',
      secondary: 'tw-bg-grey025 tw-text-text-primary',
      third: 'tw-text-navy040 tw-bg-navy010',
      fourth:
        'tw-bg-white tw-text-text-primary tw-border-1 tw-border-solid tw-border-grey040',
      cta: 'tw-text-white tw-bg-navy050 disabled:tw-text-grey040 disabled:tw-bg-grey025',
      // TODO: 디자인 파운데이션 정책 확인 필요
      sixth:
        'tw-bg-white tw-text-navy050 tw-border-1 tw-border-solid tw-border-navy050',
      naver: 'tw-bg-naver tw-text-white',
    },
    buttonSize: {
      small: 'tw-h-40',
      medium: 'tw-h-48',
      large: 'tw-h-56',
    },
    fontSize: {
      medium: 'tw-text-14',
      large: 'tw-text-16',
    },
  },
  /* 
    항공권 디자인에 세 가지 부류의 버튼 스타일이 있습니다.
    buttonSize(height) + fontSize를 조합하여 알맞는 padding을 적용합니다.
    */
  compoundVariants: [
    {
      buttonSize: 'small',
      fontSize: 'medium',
      class: 'tw-py-10 tw-px-16',
    },
    {
      buttonSize: 'medium',
      fontSize: 'large',
      class: 'tw-p-12',
    },
    {
      buttonSize: 'large',
      fontSize: 'large',
      class: 'tw-py-[15px] tw-px-16',
    },
  ],
})
