import { Icon } from '@socar-inc/socar-design-system'

export interface PlusMinusButtonProps {
  buttonType: 'plus' | 'minus'
  disabled?: boolean
  colorDisabled?: boolean
  onClick: VoidFunction
}

export const PlusMinusButton = ({
  buttonType,
  disabled,
  colorDisabled,
  onClick,
}: PlusMinusButtonProps) => (
  <button
    type="button"
    disabled={disabled}
    onClick={onClick}
    className="tw-flex tw-h-24 tw-w-24 tw-items-center tw-justify-center tw-rounded-full tw-border-1 tw-border-solid tw-border-grey030">
    <Icon
      icon={buttonType === 'plus' ? 'ic18_plus' : 'ic18_minus'}
      color={disabled || colorDisabled ? 'grey040' : 'grey050'}
    />
  </button>
)
