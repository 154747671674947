import {
  QueryClient,
  useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query'

import { PassengerApi, type ResponseListPassengerInfoDto } from '@src/api'
import { usePrefetchQuery } from '@src/hooks/usePrefetchQuery'

const api = new PassengerApi()

const getPassengersQueryKey = ['passengers']
export const getPassengers = () =>
  api.getPassengerInfo({ credentials: 'include' })

export const useGetPassengers = (
  options?: Omit<UseQueryOptions<ResponseListPassengerInfoDto>, 'queryKey'>
) => {
  return useQuery({
    queryKey: getPassengersQueryKey,
    queryFn: getPassengers,
    ...options,
  })
}

export const fetchPassengers = (queryClient: QueryClient) =>
  queryClient.fetchQuery({
    queryKey: getPassengersQueryKey,
    queryFn: getPassengers,
  })

export const usePrefetchPassengers = ({ enabled }: { enabled?: boolean }) =>
  usePrefetchQuery(
    {
      queryKey: getPassengersQueryKey,
      queryFn: getPassengers,
    },
    enabled
  )
