export type FlightType = 'domestic' | 'international'

export interface FlightsProps {
  type: FlightType
  isLoggedIn: boolean
}

export const SORT_BY_LABEL = {
  PRICE_ASC: '낮은 가격 순',
  DEPARTURE_TIME_ASC: '출발시간 빠른 순',
  DEPARTURE_TIME_DESC: '출발시간 늦은 순',
} as const

export const SORT_BY = {
  PRICE_ASC: 'PRICE_ASC',
  DEPARTURE_TIME_ASC: 'DEPARTURE_TIME_ASC',
  DEPARTURE_TIME_DESC: 'DEPARTURE_TIME_DESC',
} as const
export type SortBy = keyof typeof SORT_BY

export const FlightStatus = {
  UNSPECIFIED: 'UNSPECIFIED',
  ONE_WAY_UNSELECTED: 'ONE_WAY_UNSELECTED',
  ONE_WAY_SELECTED: 'ONE_WAY_SELECTED',
  ROUND_TRIP_UNSELECTED: 'ROUND_TRIP_UNSELECTED',
  ROUND_TRIP_OUTBOUND_SELECTED: 'ROUND_TRIP_OUTBOUND_SELECTED',
  ROUND_TRIP_INBOUND_SELECTED: 'ROUND_TRIP_INBOUND_SELECTED',
  ROUND_TRIP_OUTBOUND_RESERVED: 'ROUND_TRIP_OUTBOUND_RESERVED',
  ROUND_TRIP_ONLY_INBOUND_SELECTED: 'ROUND_TRIP_ONLY_INBOUND_SELECTED',
} as const
export type FlightStatus = (typeof FlightStatus)[keyof typeof FlightStatus]

export const DEFAULT_FILTERS = {
  DEP_TIME: [
    {
      code: 'A',
      name: '전체',
      selected: true,
    },
    {
      code: 'EARLY_MORNING',
      name: '00:00 ~ 06:00',
      selected: true,
    },
    {
      code: 'MORNING',
      name: '06:00 ~ 12:00',
      selected: true,
    },
    {
      code: 'AFTERNOON',
      name: '12:00 ~ 18:00',
      selected: true,
    },
    {
      code: 'EVENING',
      name: '18:00 ~ 24:00',
      selected: true,
    },
  ],
  CABIN_CLASS: [
    {
      code: 'A',
      name: '전체',
      selected: true,
    },
    {
      code: 'Y',
      name: '일반석',
      selected: true,
    },
    {
      code: 'V',
      name: '할인석',
      selected: true,
    },
    {
      code: 'S',
      name: '특가석',
      selected: true,
    },
    {
      code: 'C',
      name: '비즈니스석',
      selected: true,
    },
  ],
}

export interface FlightQueryParams {
  status?: 'isOutBoundReserved'
  reservedPnrNo?: string
  retry?: boolean

  /**
   * Reserved Flight Card
   */
  airCode: string
  airName: string
  cabinClass: string
  displayAmount: string
  adultAmount: string
  adultTicketAmount: string
  adultTax: string
  adultFuelSurcharge: string
  adultTasf: string
  adultCount: string
  childAmount: string
  childTicketAmount: string
  childTax: string
  childFuelSurcharge: string
  childTasf: string
  childCount: string
  infantAmount: string
  infantTicketAmount: string
  infantTax: string
  infantFuelSurcharge: string
  infantTasf: string
  infantCount: string
  flightNo: string
  depCityName: string
  depCityCode: string
  depDate: string
  depTime: string
  arrCityName: string
  arrCityCode: string
  arrDate: string
  arrTime: string
  returnDepDate: string
  returnArrDate: string
  flightTime: string
  codeShare: string
  codeShareName: string

  type: string | null
}

export interface ReservedScheduleDto {
  airCode: string
  airName: string
  cabinClass: string
  displayAmount: string
  adultAmount: string
  adultTicketAmount: string
  adultTax: string
  adultFuelSurcharge: string
  adultTasf: string
  adultCount: string
  childAmount: string
  childTicketAmount: string
  childTax: string
  childFuelSurcharge: string
  childTasf: string
  childCount: string
  infantAmount: string
  infantTicketAmount: string
  infantTax: string
  infantFuelSurcharge: string
  infantTasf: string
  infantCount: string
  flightNo: string
  depCityName: string
  depDate: string
  depTime: string
  arrCityName: string
  arrDate: string
  arrTime: string
  flightTime: string
  codeShare: string
  codeShareName: string
}
