import { ArrowLeftRight } from '@src/components/common/svgs/ArrowLeftRight'
import { ArrowRight } from '@src/components/common/svgs/ArrowRight'

import { useSSRRecoilValue } from '@src/hooks/useSSRRecoilValue'
import { useUpdateRecentSearches } from '@src/hooks/useUpdateRecentSearches'
import { itineraryTypeState } from '@src/store/atoms/search/common'
import {
  travelEndDateState,
  travelStartDateState,
} from '@src/store/atoms/search/date'
import {
  adultCountState,
  childCountState,
  infantCountState,
  seatTypesState,
} from '@src/store/atoms/search/personnel'
import {
  type RecentSearch as RecentSearchType,
  recentSearchesState,
} from '@src/store/atoms/search/recentSearch'
import {
  arrivalState,
  departureState,
} from '@src/store/atoms/search/travelDestination'
import {
  formatDateWithMonthDayAndDayOfWeek,
  formatDateWithYearMonthDayAndDayOfWeek,
} from '@src/utils/date'
import { refinedPersonnelSeatTypes } from '@src/utils/recentSearch'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

export const RecentSearch = () => {
  const router = useRouter()
  const recentSearches = useSSRRecoilValue([], recentSearchesState)
  const setItineraryType = useSetRecoilState(itineraryTypeState)
  const setDeparture = useSetRecoilState(departureState)
  const setArrival = useSetRecoilState(arrivalState)
  const setTravelStartDate = useSetRecoilState(travelStartDateState)
  const setTravelEndDate = useSetRecoilState(travelEndDateState)
  const setAdultCount = useSetRecoilState(adultCountState)
  const setChildCount = useSetRecoilState(childCountState)
  const setInfantCount = useSetRecoilState(infantCountState)
  const setSeatTypes = useSetRecoilState(seatTypesState)

  const updateRecentSearches = useUpdateRecentSearches()

  const handleCardClick = (recentSearch: RecentSearchType) => {
    const {
      itineraryType,
      departure,
      arrival,
      travelStartDate,
      travelEndDate,
      adultCount,
      childCount,
      infantCount,
      seatTypes,
    } = recentSearch

    router.push('/flights/domestic')

    setItineraryType(itineraryType)
    setDeparture(departure)
    setArrival(arrival)
    setTravelStartDate(travelStartDate)
    setTravelEndDate(travelEndDate)
    setAdultCount(adultCount)
    setChildCount(childCount)
    setInfantCount(infantCount)
    setSeatTypes(seatTypes)

    updateRecentSearches()
  }

  useEffect(() => {
    updateRecentSearches()
  }, [updateRecentSearches])

  if (recentSearches.length === 0) {
    return null
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-y-12 tw-py-16">
      <h2 className="title2 tw-font-semibold tw-text-text-initial">
        최근 검색한 항공권
      </h2>
      <div className="tw-flex tw-gap-x-8 tw-overflow-x-auto">
        {recentSearches.map((recentSearch, index) => {
          const {
            itineraryType,
            departure,
            arrival,
            travelStartDate,
            travelEndDate,
            adultCount,
            childCount,
            infantCount,
            seatTypes,
          } = recentSearch

          if (
            departure.code === null ||
            arrival.code === null ||
            travelStartDate === null
          ) {
            return null
          }

          const roundTripEndDate = travelEndDate
            ? travelStartDate.getFullYear() === travelEndDate.getFullYear()
              ? formatDateWithMonthDayAndDayOfWeek(travelEndDate)
              : formatDateWithYearMonthDayAndDayOfWeek(travelEndDate)
            : ''

          const travelDate =
            itineraryType === 'ONE_WAY'
              ? formatDateWithYearMonthDayAndDayOfWeek(travelStartDate)
              : `${formatDateWithYearMonthDayAndDayOfWeek(travelStartDate)} ~ ${roundTripEndDate}`

          const personnelSeatTypes = refinedPersonnelSeatTypes({
            adultCount,
            childCount,
            infantCount,
            seatTypes,
          })

          /** TODO-F: 추후 Map 을 사용하여 키 값 리팩토링 */
          // const key = `${departure.code}${arrival.code}${personnelSeatTypes}${travelStartDate}${travelEndDate ? travelEndDate : ''}`

          return (
            <div
              key={index}
              role="button"
              onClick={() => handleCardClick(recentSearch)}
              className="tw-flex tw-min-w-[233px] tw-flex-col tw-gap-y-10 tw-rounded-10 tw-border-1 tw-border-solid tw-border-grey030 tw-bg-white tw-px-16 tw-py-12">
              <span className="caption2 tw-w-fit tw-rounded-4 tw-bg-navy030 tw-px-6 tw-pb-2 tw-pt-4 tw-text-white">
                {itineraryType === 'ONE_WAY' ? '편도' : '왕복'}
              </span>
              <div className="tw-flex tw-flex-col">
                <div className="title3 tw-flex tw-w-full tw-items-center tw-gap-x-2 tw-font-normal">
                  <span className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
                    {departure.name}
                  </span>
                  {itineraryType === 'ONE_WAY' ? (
                    <ArrowRight className="tw-flex-shrink-0" />
                  ) : (
                    <ArrowLeftRight />
                  )}
                  <span className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
                    {arrival.name}
                  </span>
                </div>
                <div className="title4 tw-mt-8 tw-flex tw-flex-col tw-gap-y-2 tw-font-normal tw-text-text-secondary">
                  <span>{travelDate}</span>
                  <span>{personnelSeatTypes}</span>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
