import { useState } from 'react'
import { twJoin } from 'tailwind-merge'
import { ArrowLeftRightInCircle } from '@src/components/common/svgs/ArrowLeftRightInCircle'
import { match } from 'ts-pattern'
import {
  arrivalState,
  departureState,
  TravelDestination,
} from '@src/store/atoms/search/travelDestination'
import { Icon } from '@socar-inc/socar-design-system'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  isBasicFlowState,
  isSearchModalOpenState,
  itineraryTypeState,
  searchTypeState,
} from '@src/store/atoms/search/common'
import { TRAVEL_DESTINATION_DEFAULT } from '@src/store/constants/search/defaultValue'

import { SearchCard } from './SearchCard'
import { useGetDomesticAirports } from './queries'
import { motion, useAnimate } from 'framer-motion'
import { analyticsLog } from '@src/utils/analyticsLog'
import { COMPONENT_NAME, PAGE_NAME } from '@src/utils/analyticsLog/constants'

export const TravelDestinationSelector = () => {
  const [searchType, setSearchType] = useRecoilState(searchTypeState)
  const [departure, setDeparture] = useRecoilState(departureState)
  const [arrival, setArrival] = useRecoilState(arrivalState)
  const isBasicFlow = useRecoilValue(isBasicFlowState)
  const itineraryType = useRecoilValue(itineraryTypeState)
  const setIsSearchModalOpen = useSetRecoilState(isSearchModalOpenState)

  const [scope, animate] = useAnimate()

  const [isDepartureOpen, setIsDepartureOpen] = useState(
    searchType === 'DEPARTURE' ? true : false
  )
  const { data: airportsData } = useGetDomesticAirports()
  const airports = airportsData?.data

  const handleTargetClick = (target: string) => {
    if (target === 'departure') {
      setIsDepartureOpen(true)
    } else if (!departure.code) {
      alert('출발지를 선택해 주세요.')
    } else {
      setIsDepartureOpen(false)
    }
  }

  const handleTargetChange = () => {
    match({ departure, arrival })
      .with({ departure, arrival: TRAVEL_DESTINATION_DEFAULT }, () => {
        setDeparture(TRAVEL_DESTINATION_DEFAULT)
        setArrival(departure)
      })
      .with({ departure: TRAVEL_DESTINATION_DEFAULT, arrival }, () => {
        setDeparture(arrival)
        setArrival(TRAVEL_DESTINATION_DEFAULT)
      })
      .with({ departure, arrival }, () => {
        setDeparture(arrival)
        setArrival(departure)
      })
  }

  const handleAirportClick = async (travelDestination: TravelDestination) => {
    if (isDepartureOpen) {
      setDeparture(travelDestination)
      analyticsLog('click', [
        { key: 'page_name', value: PAGE_NAME.flight_main },
        { key: 'component_name', value: COMPONENT_NAME.main_tab_departure },
      ])
      setIsDepartureOpen(false)

      await animate(scope.current, {
        duration: 0.5,
        backgroundColor: '#F0FAFF',
      })
      await animate(scope.current, {
        duration: 0.5,
        backgroundColor: '#FFFFFF',
      })

      return
    }

    setArrival(travelDestination)
    analyticsLog('click', [
      { key: 'page_name', value: PAGE_NAME.flight_main },
      { key: 'component_name', value: COMPONENT_NAME.main_tab_destination },
    ])

    if (isBasicFlow) {
      setSearchType(itineraryType)
    } else {
      setIsSearchModalOpen(false)
    }
  }

  const isDepartureExist = Boolean(departure.code)
  const isArrivalExist = Boolean(arrival.code)

  return (
    <SearchCard title="출발, 도착지를 선택해 주세요.">
      <div
        className="tw-flex tw-h-full tw-flex-col tw-border-0 tw-border-t-1 tw-border-solid tw-border-grey030"
        role="main">
        <section className="tw-relative tw-flex">
          <motion.div
            initial={{ backgroundColor: '#FFFFFF' }}
            ref={scope}
            className={twJoin(
              'tw-flex tw-flex-1 tw-flex-col tw-items-center tw-justify-center tw-gap-y-2 tw-px-20 ',
              'tw-border-0  tw-border-r-1 tw-border-solid tw-border-grey030',
              !isDepartureOpen && 'tw-border-b-1',
              isDepartureExist ? 'tw-py-14' : 'tw-py-24'
            )}
            role="button"
            onClick={() => handleTargetClick('departure')}>
            <span
              className={twJoin(
                'title1 tw-font-semibold tw-text-grey045',
                isDepartureExist && 'tw-text-text-initial'
              )}>
              {isDepartureExist ? departure.name : '출발지'}
            </span>
            {isDepartureExist && (
              <span className="title4 tw-font-normal tw-text-text-secondary">
                {departure.code}
              </span>
            )}
          </motion.div>
          <div
            className={twJoin(
              'tw-flex tw-flex-1 tw-flex-col tw-items-center tw-justify-center tw-gap-y-2 tw-px-20',
              'tw-border-0  tw-border-solid tw-border-grey030',
              isDepartureOpen && 'tw-border-b-1',
              isArrivalExist ? 'tw-py-14' : 'tw-py-24'
            )}
            role="button"
            onClick={() => handleTargetClick('arrival')}>
            <span
              className={twJoin(
                'title1 tw-font-semibold tw-text-grey045',
                isArrivalExist && 'tw-text-text-initial'
              )}>
              {isArrivalExist ? arrival.name : '도착지'}
            </span>
            {isArrivalExist && (
              <span className="title4 tw-font-normal tw-text-text-secondary">
                {arrival.code}
              </span>
            )}
          </div>
          <div
            className="tw-absolute tw-left-half tw-top-half tw-translate-half"
            onClick={handleTargetChange}>
            <ArrowLeftRightInCircle />
          </div>
        </section>
        <section className="tw-flex tw-w-full tw-flex-1">
          <div className="tw-flex tw-w-[36%] tw-flex-col tw-rounded-bl-16 tw-bg-grey025">
            <button className="body1 tw-bg-white tw-py-12">국내</button>
          </div>
          <div className="tw-flex tw-w-[64%] tw-flex-col tw-overflow-y-auto tw-rounded-br-16">
            {airports?.map((airport) => {
              const airportCode = airport.cityCode
              const airportName = airport.cityName.kr

              const isDepartureSelected =
                isDepartureOpen && departure.code === airportCode

              const isArrivalSelected =
                !isDepartureOpen && arrival.code === airportCode

              const isAlreadySelected = isDepartureOpen
                ? arrival.code === airportCode
                : departure.code === airportCode

              return (
                <button
                  key={airportCode}
                  className={twJoin(
                    'title2 tw-flex tw-justify-between tw-bg-white tw-px-20 tw-py-12 tw-text-left last:tw-rounded-br-16',
                    isDepartureSelected || isArrivalSelected
                      ? 'tw-font-bold'
                      : 'tw-font-normal',
                    isAlreadySelected && 'tw-text-grey040'
                  )}
                  onClick={() =>
                    handleAirportClick({
                      code: airportCode,
                      name: airportName,
                    })
                  }
                  disabled={isAlreadySelected}>
                  {airportName}
                  {(isDepartureSelected || isArrivalSelected) && (
                    <Icon icon="ic24_checkonly" color="grey060" />
                  )}
                </button>
              )
            })}
          </div>
        </section>
      </div>
    </SearchCard>
  )
}
