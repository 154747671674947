import { useRouter } from 'next/router'

/**
 * goToSocialSignUp()
 * - @param redirectUrl: string - 소셜 로그인 후 리다이렉트할 URL
 * - @param allowGuest: boolean - 소셜 로그인 페이지에서 게스트 로그인을 허용할지 여부
 *
 * @returns 소셜 로그인 페이지로 이동하는 함수를 제공합니다.
 */
export const useGoToSocialSignUp = () => {
  const router = useRouter()

  const socialSignUpUrl = process.env.NEXT_PUBLIC_SOCIAL_SIGNUP_URL

  const goToSocialSignUp = (redirectUrl: string, allowGuest?: boolean) => {
    const socialSignUpPageUrl = new URL('/auth/sign-in', socialSignUpUrl)
    const redirectUri = new URL(redirectUrl, window.location.origin).href

    const searchParams = new URLSearchParams({
      redirectUri,
      channel: 'SOCAR_FLIGHT',
    })
    if (allowGuest) {
      searchParams.set('allowGuest', 'true')
    }

    socialSignUpPageUrl.search = searchParams.toString()

    router.push(socialSignUpPageUrl.href)
  }

  return { goToSocialSignUp }
}
