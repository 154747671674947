import { memo } from 'react'
import type { DatesProps } from '.'
import { match, P } from 'ts-pattern'
import { twMerge } from 'tailwind-merge'
import dayjs from 'dayjs'

interface DateItemProps extends Omit<DatesProps, 'monthlyActualDate'> {
  dateItemIndex: number
}

export const DateItem = memo(
  ({
    startDate,
    endDate,
    todayDate,
    currentYear,
    additionalYear,
    currentMonthIndex,
    timeRangeIndex,
    dateItemIndex,
    isPastDateActive,
    startDateCharacter,
    todayCharacter,
    endDateCharacter,
    currentMonthHolidays,
    handleDateItemClick,
  }: DateItemProps) => {
    const targetYear = currentYear + additionalYear
    const targetMonth = (currentMonthIndex + timeRangeIndex) % 12
    const targetDay = dateItemIndex + 1
    const targetDateObject = new Date(targetYear, targetMonth, targetDay)
    const targetValue = targetDateObject.valueOf()

    const [todayYear, todayMonth, todayDay] = [
      todayDate.getFullYear(),
      todayDate.getMonth(),
      todayDate.getDate(),
    ]

    const isStartDay = Number(startDate?.valueOf()) === targetValue
    const isEndDay = Number(endDate?.valueOf()) === targetValue
    const isSameDay = isStartDay && isEndDay
    const isStartOfTheMonth =
      dayjs(targetValue).startOf('month').date() === targetDay
    const isEndOfTheMonth =
      dayjs(targetValue).endOf('month').date() === targetDay

    const actualMonth = targetMonth + 1
    const todayString =
      String(currentYear + additionalYear) +
      String(actualMonth / 10 < 1 ? `0${actualMonth}` : actualMonth) +
      String(targetDay / 10 < 1 ? `0${targetDay}` : targetDay)

    const holiday = currentMonthHolidays?.find(
      (holidays) => holidays.date === todayString
    )
    const isHoliday = holiday !== undefined

    // 년, 월, 일만 비교하여 오늘인지 판단
    const isToday =
      targetDateObject.getFullYear() === todayYear &&
      targetDateObject.getMonth() === todayMonth &&
      targetDateObject.getDate() === todayDay
    const isPastDate = targetValue.valueOf() < todayDate.valueOf() && !isToday

    const isDateSelectable = !isPastDateActive && isPastDate
    const isSunday = targetDateObject.getDay() === 0
    const isSaturday = targetDateObject.getDay() === 6
    const isSelectedRange =
      Number(targetValue.valueOf()) > Number(startDate?.valueOf()) &&
      Number(targetValue.valueOf()) < Number(endDate?.valueOf())

    const targetDayColorStyle = match({
      isDateSelectable,
      isStartDay,
      isEndDay,
      isSunday,
      isHoliday,
    })
      .with({ isDateSelectable: true }, () => 'tw-text-grey040')
      .with(
        P.when(({ isStartDay, isEndDay }) => isStartDay || isEndDay),
        () => 'tw-text-white'
      )
      .with(
        P.when(({ isSunday, isHoliday }) => isSunday || isHoliday),
        () => 'tw-text-red050'
      )
      .otherwise(() => 'tw-text-grey060')

    const renderCharacter = match({
      isStartDay,
      isEndDay,
      isToday,
      isHoliday,
      isSameDay,
    })
      .with({ isSameDay: true }, () => '왕복')
      .with({ isStartDay: true }, () => startDateCharacter)
      .with({ isEndDay: true }, () => endDateCharacter)
      .with(
        { isStartDay: false, isEndDay: false, isToday: true },
        () => todayCharacter
      )
      .with(
        { isStartDay: false, isEndDay: false, isToday: false, isHoliday: true },
        () => holiday?.description
      )
      .otherwise(() => '')

    const onClick = () => {
      if (isDateSelectable) return
      handleDateItemClick(targetDateObject)
    }

    return (
      <div
        data-testid={`${targetYear}-${targetMonth}-${targetDay}`}
        className="tw-relative tw-z-0 tw-flex tw-h-[50px] tw-min-w-44 tw-grow tw-items-center tw-justify-center">
        <div
          className={twMerge(
            'tw-absolute tw-top-[-2px] tw-h-40 tw-w-full',
            isSameDay && '!tw-bg-transparent',
            startDate && endDate && isStartDay
              ? 'tw-right-[-4px] tw-rounded-l-[30px] tw-bg-grey030'
              : isEndDay && 'tw-left-[-4px] tw-rounded-r-[30px] tw-bg-grey030',
            isSelectedRange && 'tw-bg-grey030',
            isSelectedRange &&
              (isSunday || isStartOfTheMonth) &&
              'tw-rounded-l-[30px]',
            isSelectedRange &&
              (isSaturday || isEndOfTheMonth) &&
              'tw-rounded-r-[30px]'
          )}
        />
        <div
          className="tw-z-10 tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-between"
          onClick={onClick}
          role="button">
          <div
            className={twMerge(
              'tw-flex tw-h-36 tw-w-36 tw-flex-col tw-items-center tw-pt-4',
              (isStartDay || isEndDay) && 'tw-rounded-half tw-bg-grey060',
              isSameDay &&
                'tw-rounded-half tw-border-2 tw-border-solid tw-border-grey030 tw-bg-grey060'
            )}>
            <p className={twMerge('subtitle2', targetDayColorStyle)}>
              {targetDay}
            </p>
          </div>
          <p
            className={twMerge(
              'caption3 tw-h-10 tw-text-center',
              !isStartDay && !isEndDay && !isToday && isHoliday
                ? 'tw-text-red050'
                : 'tw-text-text-primary'
            )}>
            {renderCharacter}
          </p>
        </div>
      </div>
    )
  }
  // TODO: 최적화 적용
  // (prev, next) => {
  //   return (
  //     Number(prev.startDate?.valueOf()) === Number(next.startDate?.valueOf()) &&
  //     Number(prev.endDate?.valueOf()) === Number(next.endDate?.valueOf()) &&
  //     Number(prev.todayDate?.valueOf()) === Number(next.todayDate?.valueOf()) &&
  //     prev.currentYear === next.currentYear &&
  //     prev.additionalYear === next.additionalYear &&
  //     prev.currentMonthIndex === next.currentMonthIndex &&
  //     prev.timeRangeIndex === next.timeRangeIndex &&
  //     prev.dateItemIndex === next.dateItemIndex
  //   )
  // }
)
