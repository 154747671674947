import { Calendar } from '@src/components/common/svgs/Calender'
import { Person } from '@src/components/common/svgs/Person'
import { useSSRRecoilSelector } from '@src/hooks/useSSRRecoilSelector'
import { useSSRRecoilValue } from '@src/hooks/useSSRRecoilValue'
import {
  isBasicFlowState,
  isSearchModalOpenState,
  itineraryTypeState,
  searchTypeState,
} from '@src/store/atoms/search/common'
import {
  travelEndDateState,
  travelStartDateState,
} from '@src/store/atoms/search/date'
import { selectedPersonnelSeatTypeState } from '@src/store/atoms/search/personnel'
import { TRAVEL_DATE_DEFAULT } from '@src/store/constants/search/defaultValue'
import {
  formatDateWithMonthDayAndDayOfWeek,
  formatDateWithYearMonthDayAndDayOfWeek,
} from '@src/utils/date'
import { useSetRecoilState } from 'recoil'
import { twJoin } from 'tailwind-merge'

export const DatePersonnel = () => {
  const itineraryType = useSSRRecoilValue('ROUND_TRIP', itineraryTypeState)
  const travelStartDate = useSSRRecoilValue(
    TRAVEL_DATE_DEFAULT,
    travelStartDateState
  )
  const travelEndDate = useSSRRecoilValue(
    TRAVEL_DATE_DEFAULT,
    travelEndDateState
  )
  const selectedPersonnelSeatType = useSSRRecoilSelector(
    '',
    selectedPersonnelSeatTypeState
  )
  const setIsSearchModalOpen = useSetRecoilState(isSearchModalOpenState)
  const setIsBasicFlow = useSetRecoilState(isBasicFlowState)
  const setSearchType = useSetRecoilState(searchTypeState)

  const handleTargetClick = (target: string) => {
    if (target === 'date') {
      setSearchType(itineraryType)
    } else {
      setSearchType('PERSONNEL')
    }

    setIsBasicFlow(false)
    setIsSearchModalOpen(true)
  }

  const roundTripDate =
    travelStartDate && travelEndDate
      ? travelStartDate.getFullYear() === travelEndDate.getFullYear()
        ? `${formatDateWithYearMonthDayAndDayOfWeek(travelStartDate)} ~ ${formatDateWithMonthDayAndDayOfWeek(travelEndDate)}`
        : `${formatDateWithYearMonthDayAndDayOfWeek(travelStartDate)} ~ ${formatDateWithYearMonthDayAndDayOfWeek(travelEndDate)}`
      : '가는날 - 오는날 선택'

  const oneWayDate = travelStartDate
    ? formatDateWithYearMonthDayAndDayOfWeek(travelStartDate)
    : '가는날 선택'

  const roundTripDateStyle =
    travelStartDate && travelEndDate
      ? 'tw-font-bold tw-text-text-initial'
      : 'tw-font-semibold tw-text-grey040'
  const oneWayDateStyle = travelStartDate
    ? 'tw-font-bold tw-text-text-initial'
    : 'tw-font-semibold tw-text-grey040'

  return (
    <div className="tw-rounded-16 tw-border-1 tw-border-solid  tw-border-grey030 tw-bg-white">
      <div
        role="button"
        className="tw-flex tw-items-center tw-justify-start tw-gap-x-16 tw-px-24 tw-pb-12 tw-pt-18"
        onClick={() => handleTargetClick('date')}>
        <Calendar />
        <span
          className={twJoin(
            'body1',
            itineraryType === 'ROUND_TRIP'
              ? roundTripDateStyle
              : oneWayDateStyle
          )}>
          {itineraryType === 'ROUND_TRIP' ? roundTripDate : oneWayDate}
        </span>
      </div>
      <div
        role="button"
        className="tw-flex tw-items-center tw-justify-start tw-gap-x-16 tw-px-24 tw-pb-18 tw-pt-12"
        onClick={() => handleTargetClick('personnel')}>
        <Person />
        <span className="body1 tw-font-bold tw-text-text-initial">
          {selectedPersonnelSeatType}
        </span>
      </div>
    </div>
  )
}
