import { APIApi, ResponseListAreaDto } from '@src/api'
import { usePrefetchQuery } from '@src/hooks/usePrefetchQuery'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'

const flightApi = new APIApi()

export const useGetDomesticAirports = (
  options?: UseQueryOptions<ResponseListAreaDto>
) => {
  return useQuery({
    queryKey: ['domesticAirports'],
    queryFn: () => flightApi.getAreas(),
    staleTime: Infinity,
    ...options,
  })
}

export const usePrefetchDomesticAirports = () =>
  usePrefetchQuery({
    queryKey: ['domesticAirports'],
    queryFn: () => flightApi.getAreas(),
  })
