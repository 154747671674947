import { APIApi, ResponseHolidaysGetResponseDto } from '@src/api'
import { usePrefetchQuery } from '@src/hooks/usePrefetchQuery'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'

const flightApi = new APIApi()

export const useGetHolidaysMap = (
  options?: UseQueryOptions<ResponseHolidaysGetResponseDto>
) => {
  return useQuery({
    queryKey: ['holidaysMap'],
    queryFn: () => flightApi.getHolidays(),
    staleTime: Infinity,
    ...options,
  })
}

export const usePrefetchHolidaysMap = () =>
  usePrefetchQuery({
    queryKey: ['holidaysMap'],
    queryFn: () => flightApi.getHolidays(),
  })
