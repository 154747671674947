import { Banner } from '@src/components/ui/banners'
import { useGetBannersQuery } from '@src/components/ui/banners/queries'
import { BottomSheet } from '@src/components/ui/bottom-sheet'
import { Dim } from '@src/components/ui/dim'
import { useGoToSocialSignUp } from '@src/hooks/useGoToSocialSignUp'
import LoginBottomSheet from '@src/views/Login/LoginBottomSheet'
import { useInvalidateSessionMutation } from '@src/views/Login/mutations'
import { usePrefetchPassengers } from '@src/views/Passengers/queries'
import { usePrefetchNationsQuery } from '@src/views/Reservation/queries'
import { motion } from 'framer-motion'
import { signOut, useSession } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import {
  AGREEMENT_ZENDESK_LINK,
  PRIVACY_POLICY_ZENDESK_LINK,
} from './constants'
import { SidebarProps } from './types'

const VOF_API = process.env.NEXT_PUBLIC_VOF_API

export const Sidebar = ({ setIsVisible }: SidebarProps) => {
  const router = useRouter()
  const session = useSession()
  const { goToSocialSignUp } = useGoToSocialSignUp()
  const { mutate: invalidateSessionMutate } = useInvalidateSessionMutation()
  const [isLoginBottomSheetOpen, setIsLoginBottomSheetOpen] = useState(false)

  const user = session.data?.user
  const isLoggedIn = Boolean(user?.isLoggedIn)

  usePrefetchPassengers({ enabled: isLoggedIn })
  usePrefetchNationsQuery({
    enabled: isLoggedIn,
  })

  const { data: banner } = useGetBannersQuery(
    {
      position: 'RIGHT',
    },
    {
      select: (response) => ({
        ...response,
        data: response?.data?.filter(
          (banner) => banner.display === 'Y' && banner.sortOrder === 0
        ),
      }),
    }
  )
  const bannerImageSrc =
    banner?.data?.[0]?.pcImgPath || '/_upload/bannermanage/banner_sidebar.png'
  const bannerImageAlt = banner?.data?.[0]?.title || ''
  const bannerLinkUrl = banner?.data?.[0]?.pcLinkUrl || '#'

  const handleClose = () => setIsVisible(false)

  const handleLogIn = () => goToSocialSignUp('/')

  const handleLogout = () => {
    if (confirm('정말 로그아웃을\n하시겠어요?')) {
      invalidateSessionMutate(undefined, {
        onSuccess: async () => {
          await signOut({ redirect: false, callbackUrl: '/logout' })
          router.replace(router.asPath)
        },
        onError: async (error) => {
          await signOut({ redirect: false, callbackUrl: '/logout' }) // TODO-F: 임시로 invalidate 실패해도 로그아웃 처리, 추후에는 특정 코드를 받아 처리
          router.replace(router.asPath)
          console.error('invalidate session Error: ', error)
        },
        onSettled: () => setIsVisible(false),
      })
    }
  }

  const handleClickReservation = () => {
    if (isLoggedIn) {
      setIsVisible(false)
      router.push('/reservations')
    } else {
      setIsLoginBottomSheetOpen(true)
    }
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <>
      <motion.aside
        className={twMerge(
          'tw-fixed tw-bottom-0 tw-right-0 tw-top-0 tw-flex tw-w-[318px] tw-flex-col tw-bg-white ',
          isLoginBottomSheetOpen ? 'tw-z-[10000]' : 'tw-z-[10001]'
        )}
        transition={{ duration: 0.2, ease: 'easeInOut' }}
        initial={{ x: '100%' }}
        animate={{ x: '0%' }}
        exit={{ x: '100%' }}>
        <section className="tw-px-24 tw-pb-28 tw-pt-36">
          {isLoggedIn ? (
            <>
              <h2
                role="button"
                className="header2 tw-mb-4 tw-font-bold tw-text-grey060">
                {user?.name}
              </h2>
              <p className="title4 tw-font-normal tw-text-grey050">
                {user?.email}
              </p>
            </>
          ) : (
            <>
              <button
                className="header2 tw-mb-4 tw-font-bold tw-text-grey060"
                onClick={handleLogIn}>
                로그인 • 회원가입
              </button>

              <p className="title4 tw-font-normal tw-text-grey050">
                쏘카항공에서 다양한 혜택을 누려보세요!
              </p>
            </>
          )}
        </section>
        <Banner
          position="RIGHT"
          linkUrl={bannerLinkUrl}
          src={`${VOF_API}${bannerImageSrc}`}
          alt={bannerImageAlt}
        />
        <nav className="tw-grow">
          <ul className="body1 tw-flex tw-flex-col tw-border-0 tw-border-b-1 tw-border-solid tw-border-grey030 tw-py-8 tw-text-grey060">
            <li className="tw-px-24 tw-py-12">
              <button onClick={handleClickReservation}>예약 내역</button>
            </li>
            {isLoggedIn && (
              <li className="tw-px-24 tw-py-12">
                <Link href="/passengers" onClick={handleClose}>
                  탑승객
                </Link>
              </li>
            )}
          </ul>
          <ul className="body2 tw-flex tw-flex-col tw-py-8 tw-text-grey050">
            <li className="tw-px-24 tw-py-12">
              <Link href="/notice" onClick={handleClose}>
                공지사항
              </Link>
            </li>
            <li className="tw-px-24 tw-py-12">
              <Link href="/faq" onClick={handleClose}>
                FAQ
              </Link>
            </li>
            <li className="tw-px-24 tw-py-12">
              <Link
                href={AGREEMENT_ZENDESK_LINK}
                target="_blank"
                rel="noopenner noreferer">
                이용약관
              </Link>
            </li>
            <li className="tw-px-24 tw-py-12">
              <Link
                href={PRIVACY_POLICY_ZENDESK_LINK}
                target="_blank"
                rel="noopenner noreferer">
                개인정보처리방침
              </Link>
            </li>
            {isLoggedIn && (
              <li className="tw-px-24 tw-py-12">
                <Link
                  href={`${process.env.NEXT_PUBLIC_SOCIAL_SIGNUP_URL}/withdrawal?redirectUri=${process.env.NEXT_PUBLIC_CLIENT_URL}`}>
                  회원탈퇴
                </Link>
              </li>
            )}
          </ul>
        </nav>
        {isLoggedIn && (
          <div className="tw-px-24 tw-pb-[46px] tw-pt-16">
            <button
              type="button"
              onClick={handleLogout}
              className="title4 tw-w-full tw-rounded-8 tw-border-1 tw-border-solid tw-border-grey030 tw-px-16 tw-py-8 tw-font-normal tw-text-text-secondary">
              로그아웃
            </button>
          </div>
        )}
      </motion.aside>
      <Dim onClick={handleClose} />

      {/* 예약 내역 확인 */}
      <BottomSheet.Root onBeforeClose={() => setIsLoginBottomSheetOpen(false)}>
        <LoginBottomSheet
          isOpen={isLoginBottomSheetOpen}
          reason="예약 내역 확인을 위해"
          guestButtonText="비회원 예약조회"
          handleClickMemberLogin={() => goToSocialSignUp('/reservations')}
          handleClickGuestLogin={() => {
            router.push('/reservations-guest/check-info')
            handleClose()
          }}
        />
      </BottomSheet.Root>
    </>
  )
}
