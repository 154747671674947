import { Parameter } from '@socar-inc/utils'
import { EventName } from './types'

export const analyticsLog = (type: EventName, parameters: Parameter) => {
  return (<any>window).gtag(
    'event',
    type,
    Object.fromEntries(parameters.map((v) => [v.key, v.value]))
  )
}
