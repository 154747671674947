import { useSSRRecoilState } from '@src/hooks/useSSRRecoilState'
import { itineraryTypeState } from '@src/store/atoms/search/common'
import { useSetRecoilState } from 'recoil'
import { twJoin } from 'tailwind-merge'
import { motion } from 'framer-motion'
import { travelEndDateState } from '@src/store/atoms/search/date'
import { analyticsLog } from '@src/utils/analyticsLog'
import { COMPONENT_NAME, PAGE_NAME } from '@src/utils/analyticsLog/constants'

export const JourneyToggle = () => {
  const [itineraryType, setItineraryType] = useSSRRecoilState(
    'ROUND_TRIP',
    itineraryTypeState
  )
  const setTravelEndDate = useSetRecoilState(travelEndDateState)

  return (
    <div className="tw-flex tw-items-center tw-justify-center">
      <div className="tw-flex tw-gap-x-8 tw-rounded-20 tw-bg-white tw-px-8 tw-py-6">
        <motion.button
          type="button"
          className={twJoin(
            'title3 tw-rounded-[14px] tw-px-14 tw-pb-4 tw-pt-6 tw-font-medium',
            itineraryType === 'ROUND_TRIP'
              ? 'tw-bg-temp002 tw-text-white'
              : 'tw-bg-transparent tw-text-text-tertiary'
          )}
          onClick={() => {
            setItineraryType('ROUND_TRIP')
            analyticsLog('click', [
              { key: 'page_name', value: PAGE_NAME.flight_main },
              {
                key: 'component_name',
                value: COMPONENT_NAME.main_tab_roundtrip,
              },
            ])
          }}
          animate={{ opacity: itineraryType === 'ROUND_TRIP' ? 1 : 0.5 }}
          transition={{ duration: 0.3, ease: 'easeIn' }}>
          왕복
        </motion.button>
        <motion.button
          type="button"
          className={twJoin(
            'title3 tw-rounded-[14px] tw-px-14 tw-pb-4 tw-pt-6 tw-font-medium',
            itineraryType === 'ONE_WAY'
              ? 'tw-bg-temp002 tw-text-white'
              : 'tw-bg-transparent tw-text-text-tertiary'
          )}
          onClick={() => {
            setItineraryType('ONE_WAY')
            setTravelEndDate(null)
            analyticsLog('click', [
              { key: 'page_name', value: PAGE_NAME.flight_main },
              {
                key: 'component_name',
                value: COMPONENT_NAME.main_tab_oneway,
              },
            ])
          }}
          animate={{
            opacity: itineraryType === 'ONE_WAY' ? 1 : 0.5,
          }}
          transition={{ duration: 0.3, ease: 'easeIn' }}>
          편도
        </motion.button>
      </div>
    </div>
  )
}
