import { Icon } from '@socar-inc/socar-design-system'
import { SearchCard } from './SearchCard'
import { PersonTypeItem } from './PersonTypeItem'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {
  adultCountState,
  childCountState,
  infantCountState,
  seatTypesState,
  selectedPersonnelSeatTypeState,
  sumCountState,
} from '@src/store/atoms/search/personnel'
import { isSearchModalOpenState } from '@src/store/atoms/search/common'
import { updateFilters } from '../Flights/utils'
import { INFANT_CONDITION_ALERT, PERSONNEL_MAX_ALERT } from './constants'
import { analyticsLog } from '@src/utils/analyticsLog'
import { COMPONENT_NAME, PAGE_NAME } from '@src/utils/analyticsLog/constants'

export const PersonnelSelector = () => {
  const [adultCount, setAdultCount] = useRecoilState(adultCountState)
  const [childCount, setChildCount] = useRecoilState(childCountState)
  const [infantCount, setInfantCount] = useRecoilState(infantCountState)
  const [seatTypes, setSeatTypes] = useRecoilState(seatTypesState)
  const selectedValue = useRecoilValue(selectedPersonnelSeatTypeState)

  const setIsSearchModalOpen = useSetRecoilState(isSearchModalOpenState)

  const sumCount = useRecoilValue(sumCountState)

  // 버튼 비활성화 조건
  const isAdultMinusDisable = adultCount === 1 || adultCount === 0
  const isChildMinusDisable = childCount === 0
  const isInfantMinusDisable = infantCount === 0
  const isAdultPlusDisable = adultCount === 9 || sumCount === 9
  const isChildPlusDisable = sumCount === 9
  const isInfantPlusDisable = adultCount <= infantCount || sumCount === 9

  // 전체 선택 해제 방지를 위한 조건
  const seatTypesWithoutAll = seatTypes.filter(
    (seatType) => seatType.code !== 'A'
  )
  const isExistOnlyOneSelected =
    seatTypesWithoutAll.filter((seatType) => seatType.selected).length === 1

  const handleSubmit = () => {
    setIsSearchModalOpen(false)
    analyticsLog('click', [
      {
        key: 'page_name',
        value: PAGE_NAME.flight_main,
      },
      {
        key: 'component_name',
        value: COMPONENT_NAME.main_passenger_seats_Complete_btm,
      },
    ])
  }

  return (
    <SearchCard
      title="인원 및 좌석을 선택해 주세요."
      selectedValue={selectedValue}
      buttonName="선택 완료"
      hasFooter
      onClick={handleSubmit}>
      <div role="main" className="tw-px-16 tw-pt-8">
        <section className="tw-flex tw-flex-col tw-gap-y-8">
          <PersonTypeItem
            type="ADULT"
            count={adultCount}
            isMinusDisable={isAdultMinusDisable}
            isPlusDisable={isAdultPlusDisable}
            handleMinusClick={() => {
              // 유아 좌석은 성인 좌석보다 많을 수 없다.
              if (adultCount === infantCount) {
                setInfantCount((prevCount) => prevCount - 1)
              }
              setAdultCount((prevCount) => prevCount - 1)
            }}
            handlePlusClick={() => {
              if (isAdultPlusDisable) {
                alert(PERSONNEL_MAX_ALERT)
              } else {
                setAdultCount((prevCount) => prevCount + 1)
              }
            }}
          />
          <PersonTypeItem
            type="CHILD"
            count={childCount}
            isMinusDisable={isChildMinusDisable}
            isPlusDisable={isChildPlusDisable}
            handleMinusClick={() => {
              setChildCount((prevCount) => prevCount - 1)
            }}
            handlePlusClick={() => {
              if (isChildPlusDisable) {
                alert(PERSONNEL_MAX_ALERT)
              } else {
                setChildCount((prevCount) => prevCount + 1)
              }
            }}
          />
          <PersonTypeItem
            type="INFANT"
            count={infantCount}
            isMinusDisable={isInfantMinusDisable}
            isPlusDisable={isInfantPlusDisable}
            handleMinusClick={() => {
              setInfantCount((prevCount) => prevCount - 1)
            }}
            handlePlusClick={() => {
              if (sumCount === 9) {
                alert(PERSONNEL_MAX_ALERT)
              } else if (isInfantPlusDisable) {
                alert(INFANT_CONDITION_ALERT)
              } else {
                setInfantCount((prevCount) => prevCount + 1)
              }
            }}
          />
          <div className="tw-flex tw-flex-col tw-gap-y-14 tw-rounded-8 tw-bg-grey020 tw-px-16 tw-py-14">
            <div className="tw-flex tw-items-center tw-justify-between tw-gap-x-[15px]">
              <Icon icon="ic18_info_line" color="grey050" />
              <p className="title4 tw-font-normal tw-text-text-secondary">
                24개월 미만 유아의 경우 좌석이 없으며, 성인이 반드시 포함되어야
                합니다.
              </p>
            </div>
            <div className="tw-flex tw-items-center tw-justify-between tw-gap-x-[15px]">
              <Icon icon="ic18_info_line" color="grey050" />
              <p className="title4 tw-font-normal tw-text-text-secondary">
                오는 편 기준으로 만 나이가 변경되는 경우 가는 편/오는 편을 각각
                편도로 예약해 주세요.
              </p>
            </div>
          </div>
        </section>
        <section>
          <h1 className="title1 tw-m-0 tw-mt-16 tw-font-semibold">좌석</h1>
          <div className="tw-grid tw-grid-cols-2 tw-grid-rows-2 tw-gap-x-[47px] tw-gap-y-16 tw-px-14 tw-pb-24 tw-pt-16">
            {seatTypes.map(
              ({ code, name, selected }) =>
                code !== 'A' && (
                  <div className="tw-flex tw-gap-x-8" key={code}>
                    <button
                      type="button"
                      id={code}
                      onClick={() => {
                        if (isExistOnlyOneSelected && selected) {
                          alert('최소 1개의 좌석 유형을\n선택해 주세요.')
                        } else {
                          updateFilters(setSeatTypes, code)
                        }
                      }}
                      className="tw-flex tw-items-center">
                      <Icon
                        icon="ic24_check_on"
                        color={selected ? 'grey060' : 'grey040'}
                      />
                    </button>
                    <label
                      htmlFor={code}
                      className="title2 tw-mt-2 tw-font-normal tw-text-grey070">
                      {name}
                    </label>
                  </div>
                )
            )}
          </div>
        </section>
      </div>
    </SearchCard>
  )
}
