import { signOut } from 'next-auth/react'
import { useRouter } from 'next/router'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useEffect } from 'react'

import { usePageTitle } from '@src/components/common/layout/PageNameProvider'
import { usePrefetchHolidaysMap } from '@src/components/DatePicker/queries'
import { CTAButton } from '@src/components/ui/CTAButton'
import { useSocialSignUp } from '@src/hooks/useSocialSignUp'
import { useSSRRecoilSelector } from '@src/hooks/useSSRRecoilSelector'
import { useUpdateRecentSearches } from '@src/hooks/useUpdateRecentSearches'
import {
  isBasicFlowState,
  isCTAButtonEnabledState,
  isSearchModalOpenState,
} from '@src/store/atoms/search/common'
import { SearchModal } from '../Search'
import { useInvalidateSessionMutation } from '../Login/mutations'
import { DatePersonnel } from './DatePersonnel'
import { JourneyToggle } from './JourneyToggle'
import { TravelDestination } from './TravelDestination'
import { RecentSearch } from './RecentSearch'
import { Footer } from './Footer'
import ReservationCTAButton from './ReservationCTAButton'
import { usePrefetchDomesticAirports } from '../Search/queries'
import { useGoToSocialSignUp } from '@src/hooks/useGoToSocialSignUp'
import { Pending } from '@src/components/common/Pending'
import { BannerSwiper } from '@src/components/ui/banners'
import { usePageQuery } from '@src/hooks/usePageQuery'
import { useFlightSnackbar } from '@src/components/common/FlightSnackbar'
import {
  LINK_MESSAGE,
  LOGIN_MESSAGE,
  SIGN_UP_MESSAGE,
} from '@src/components/common/FlightSnackbar/constants'
import { useEffectOnce } from '@src/hooks'
import { analyticsLog } from '@src/utils/analyticsLog'
import { COMPONENT_NAME, PAGE_NAME } from '@src/utils/analyticsLog/constants'

export const Main = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const router = useRouter()
  const query = usePageQuery<Record<string, string>>()
  const { showSnackbar } = useFlightSnackbar()

  const isSearchModalOpen = useRecoilValue(isSearchModalOpenState)
  const isCTAButtonEnabled = useSSRRecoilSelector(
    false,
    isCTAButtonEnabledState
  )
  const setIsBasicFlow = useSetRecoilState(isBasicFlowState)

  usePageTitle(isSearchModalOpen ? '검색' : '항공')
  usePrefetchDomesticAirports()
  usePrefetchHolidaysMap()

  const { isPending } = useSocialSignUp({
    isLoggedIn,
    successCallback: () => {
      const { SIGNUP, LINK, LOGIN } = query

      if (SIGNUP === 'OK') {
        showSnackbar(SIGN_UP_MESSAGE)
      }
      if (LINK === 'OK') {
        showSnackbar(LINK_MESSAGE)
      }
      if (LOGIN === 'OK') {
        showSnackbar(LOGIN_MESSAGE)
      }
    },
  })
  const updateRecentSearches = useUpdateRecentSearches()
  const { goToSocialSignUp } = useGoToSocialSignUp()
  const { mutate: invalidateSessionMutate } = useInvalidateSessionMutation()

  const handleCTAButtonClick = () => {
    analyticsLog('click', [
      {
        key: 'page_name',
        value: PAGE_NAME.flight_main,
      },
      {
        key: 'component_name',
        value: COMPONENT_NAME.main_flight_ticket_search_btn,
      },
    ])
    setIsBasicFlow(false)
    router.push('/flights/domestic')
  }

  const handleLogIn = () => {
    analyticsLog('click', [
      {
        key: 'page_name',
        value: PAGE_NAME.flight_main,
      },
      {
        key: 'component_name',
        value: COMPONENT_NAME.main_login,
      },
    ])
    goToSocialSignUp('/')
  }

  const handleLogout = () => {
    if (confirm('정말 로그아웃을\n하시겠어요?')) {
      invalidateSessionMutate(undefined, {
        onSuccess: async () => {
          await signOut({ redirect: false, callbackUrl: '/logout' })
          router.replace(router.asPath)
        },
        onError: async (error) => {
          await signOut({ redirect: false, callbackUrl: '/logout' }) // TODO-F: 임시로 invalidate 실패해도 로그아웃 처리, 추후에는 특정 코드를 받아 처리
          router.replace(router.asPath)
          console.error('invalidate session Error: ', error)
        },
      })
    }
  }

  useEffectOnce(() => {
    const { GUEST_SESSION, MEMBER_SESSION, RESERVATION_SESSION, ...rest } =
      query

    if (GUEST_SESSION === 'ERROR') {
      alert(
        '서비스를 장시간 이용하지 않아 홈으로 이동했어요. 원활한 이용을 위해 다시 진행해 주세요.'
      )
    }

    if (MEMBER_SESSION === 'ERROR') {
      confirm('서비스 미사용으로 로그아웃 됐습니다. 로그인하시겠어요?') &&
        goToSocialSignUp('/')
    }

    if (RESERVATION_SESSION === 'ERROR') {
      alert(
        '예약 시간이 초과되어 홈으로 이동했어요. 새로운 항공권으로 다시 검색하고, 예약을 진행해 보세요.'
      )
    }

    if (GUEST_SESSION || MEMBER_SESSION || RESERVATION_SESSION) {
      router.replace(
        {
          pathname: '/',
          query: rest,
        },
        undefined,
        { shallow: true }
      )
    }
  })

  useEffect(() => {
    return () => {
      updateRecentSearches()
    }
  }, [updateRecentSearches])

  return (
    <>
      {isPending && <Pending />}
      <header className="tw-bg-main-background tw-bg-[#1F75DB] tw-bg-contain tw-bg-bottom tw-bg-no-repeat tw-pb-[113px] tw-pt-16">
        <h1 className="header2 tw-m-0 tw-p-24 tw-pt-8 tw-font-bold tw-text-white">
          쏘카와 함께 어디든
          <br />
          떠나볼까요?
        </h1>
      </header>
      <main className="tw-flex tw-flex-col tw-bg-grey020 tw-px-16">
        <section className="tw-flex tw-flex-col tw-gap-y-16 tw-pt-16">
          <JourneyToggle />
          <TravelDestination />
          <DatePersonnel />
          <CTAButton
            type="button"
            buttonStyle="cta"
            buttonSize="large"
            fontSize="large"
            disabled={!isCTAButtonEnabled}
            onClick={handleCTAButtonClick}>
            항공권 검색하기
          </CTAButton>
          <div className="tw-flex tw-items-center tw-justify-center tw-gap-x-20 tw-py-16">
            <span
              role="button"
              onClick={isLoggedIn ? handleLogout : handleLogIn}
              className="title3 tw-font-normal tw-text-text-primary">
              {isLoggedIn ? '로그아웃' : '로그인'}
            </span>
            <div className="tw-h-16 tw-w-[1px] tw-bg-grey030" />
            <ReservationCTAButton isLoggedIn={isLoggedIn} />
          </div>
          <RecentSearch />
        </section>
        <BannerSwiper position="TOP" />
        <SearchModal />
      </main>
      <Footer />
    </>
  )
}
